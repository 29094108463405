@import '../../../styles/bootstrap';

.weightVolumeMultiplication {
	position: absolute;
	bottom: 0;
	height: 35px;
	line-height: 35px;
	right: 40px;
	color: $text-muted;
	text-align: right;
}