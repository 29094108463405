.mapMarkerNumber {
	position: absolute;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	left: 0;
	top: 0;
	color: #fff;
	font-weight: bold;
}