@import 'bootstrap';
@import '~bootstrap/scss/bootstrap';

@import 'mixins';

@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/regular';

@import 'base';
@import 'panel';
@import 'forms';
@import 'buttons';
@import 'tippyjs';
@import 'table';
@import 'input-group';
@import 'badge';

@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-toastify/dist/ReactToastify.css';
