html {
	font-size: 13px;
}

body {
	min-width: 768px;
}

.over-map {
	z-index: 1;
}

.z-index-draggable {
	z-index: 3 !important;
}

.text-small {
	font-size: $font-size-sm;
}

.text-medium {
	font-size: $font-size-base
}

.text-large {
	font-size: $font-size-lg;
}

.clear-both {
	clear: both;
}

.maxh-100 {
	max-height: 100%;
}

.cursor-pointer {
	cursor: pointer;
}

.pointer-events-none {
	pointer-events: none;
}

.overflow-initial {
	overflow: initial !important;
}

.bg-gray-200 {
	background-color: $gray-200;
}

.opacity-50 {
	opacity: .5;
}

.user-select-none {
	user-select: none;
}
.visually-hidden {
	display: none;
}