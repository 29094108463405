@import '../../styles/bootstrap';

.container {
	border: 1px solid $border-color;
	border-radius: $border-radius;
	background: white;
	padding: .9rem 1rem .7rem 3rem;
	margin-bottom: .5rem;
	position: relative;
}

.containerClickable {
	composes: container;
	cursor: pointer;

	&:hover {
		border-color: $gray-400;
	}
}

.orderStatus1 {
	box-shadow: inset 3px 0 0 $unassigned;
}
.orderStatus6 {
	box-shadow: inset 3px 0 0 $completed;
}
.orderStatus7 {
	box-shadow: inset 3px 0 0 $cancelled;
}

.orderHeaderTime {
	line-height: 1;
}


.number {
	position: absolute;
	top: .8rem;
	left: .8rem;
	width: 1.54rem;
	height: 1.54rem;
	line-height: 1.54rem;
	text-align: center;
	color: white;
	font-weight: $font-weight-semibold;
	font-size: $font-size-lg;
	border-radius: 100%;
}

.number0 {
	composes: number;
	background-color: $pickup;
}
.number1 {
	composes: number;
	background-color: $delivery;
}
.number3 {
	composes: number;
	background-color: $requisition;
}
.numberGreen {
	composes: number;
	background-color: $primary;
}

.badge {
	font-weight: $font-weight-bold;
	font-size: .92rem;
	padding: .1rem .7rem;
	background-color: $gray-200;
	display: inline-block;
	margin-right: 1px;
	margin-bottom: 1px;
}
.badgeLeft {
	composes: badge;
	border-top-left-radius: 1rem;
	border-bottom-left-radius: 1rem;
}
.badgeRight {
	composes: badge;
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
}

.ellipsis {
	position: absolute;
	top: 0;
	right: 0;
}

.requisition0 {
	// pickup
	position: absolute;
	width: 18px;
	height: 19px;
	background-image: url(../../images/requisition-arrow.svg);
	left: -28px;
	top: 0;
}
.requisition1 {
	// delivery
	composes: requisition0;
	transform: scaleY(-1);
}

.btnLocateInMap {
	position: absolute;
	bottom: 0;
	right: 0;
}