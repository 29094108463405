@import '../../styles/bootstrap';

.number {
	width: 28px;
	height: 28px;
	border: 2px solid $gray-500;
	border-radius: 100%;
	font-size: $font-size-lg;
	font-weight: $font-weight-semibold;
	line-height: 24px;
	text-align: center;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	background-color: $white;
}
.numberActive {
	composes: number;
	border-color: $primary;
	color: $primary;
}
.numberCompleted {
	composes: number;
	border-color: $primary;
	background: $primary;
	color: $white;
}
.li {
	padding-left: 42px;
	padding-top: 5px;
	padding-bottom: $spacer;
	position: relative;
}
.liNotLast {
	composes: li;
	&::before {
		content: '';
		position: absolute;
		border-left: 2px dashed $gray-500;
		height: 100%;
		left: 12px;
		z-index: 0;
	}
}