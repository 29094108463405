@import '../../styles/bootstrap';
@import '../../styles/mixins';

.mockupText {
	display: inline-block;
	height: 16px;
	background: $gray-100;
	border-radius: $border-radius-sm;
	@include PulseLoader;
}

.container {
	transition: $transition-fade;
}

.hidden {
	opacity: 0;
	pointer-events: none;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $body-bg;
	opacity: .7;
	// $zindex-modal-backdrop is used for modal overlays so when loader appears on top of a modal,
	// we need the loader overlay to have at least 1 z-index more
	z-index: $zindex-modal-backdrop + 1;
}

.truck {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: $zindex-modal;
}

.truckBody {
	animation: shake 0.2s ease-in-out infinite alternate;
}

.truckLine {
	transform-origin: center right;
	stroke-dasharray: 22;
	animation: line 0.8s ease-in-out infinite;
	animation-fill-mode: both;
}
.truckLineTop {
	composes: truckLine;
	animation-delay: 0s;
}
.truckLineMiddle {
	composes: truckLine;
	animation-delay: 0.2s;
}
.truckLineBottom {
	composes: truckLine;
	animation-delay: 0.4s;
}

@keyframes shake {
	0% {
		transform: translateY(-1%);
	}
	100% {
		transform: translateY(3%);
	}
}

@keyframes line {
	0% {
		stroke-dashoffset: 22;
	}

	25% {
		stroke-dashoffset: 22;
	}

	50% {
		stroke-dashoffset: 0;
	}

	51% {
		stroke-dashoffset: 0;
	}

	80% {
		stroke-dashoffset: -22;
	}

	100% {
		stroke-dashoffset: -22;
	}
}