@import '../../styles/bootstrap';

.orders {
	width: 300px;
	max-height: 100%;
	display: flex;
	flex-direction: column;
}

.footer {
	grid-area: footer;
	padding-left: $spacer;
	padding-right: $spacer;
}

.footerPanel {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.content {
	grid-area: content;
}