@import '../../styles/bootstrap';
@import '../../styles/mixins';
@import '../../styles/variables';

.header {
	margin: 0 15px;
	grid-area: header;
	border-bottom-left-radius: $border-radius-lg;
	border-bottom-right-radius: $border-radius-lg;
	background: $white;
	z-index: 1;
	overflow: hidden;
	@include BoxShadow;
}
.headerUpper {
	padding: 0 10px;
	display: flex;
	align-items: center;
}

.link {
	height: $navbar-height;
	line-height: $navbar-height;
	display: block;
	border: none;
	border-bottom: 3px solid transparent;
	color: $body-color;
	font-weight: $font-weight-semibold;
	padding: 0 .4rem;
	margin: 0 .4rem;
	font-size: $font-size-lg;
	white-space: nowrap;
	&:focus,
	&:hover,
	&:focus,
	&.active {
		outline: none;
		text-decoration: none;
		color: $primary;
		border-color: $primary;
	}
}

.linkCalendar {
	composes: link;
	line-height: 1.1;
}

.linkSubnav {
	composes: link;
	height: $subnavbar-height;
	line-height: $subnavbar-height;
	white-space: nowrap;
}

.badgeStatus0 {
	background-color: gray;
}
.badgeStatus1 {
	background-color: gray;
}
.badgeStatus2 {
	background-color: $pickup;
}
.badgeStatus3 {
	background-color: $warning;
}
.badgeStatus4 {
	background-color: $requisition;
}
.badgeStatus5 {
	background-color: $success;
}
.badgeStatus6 {
	background-color: $danger;
}