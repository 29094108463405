//.btn {
//	color: $secondary;
//}

.btn-link {
	font-weight: $font-weight-semibold;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary:disabled {
	color: white;
}

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-danger {
	border-color: transparent !important;
	background-color: transparent !important;
}
.btn-outline-primary {
	&:hover,
	&:active,
	&.active,
	&:focus {
		color: darken($primary, 10%) !important;
	}
}
.btn-outline-secondary {
	&:hover,
	&:active,
	&.active,
	&:focus {
		color: darken($secondary, 10%) !important;
	}
}
.btn-outline-danger {
	&:hover,
	&:active,
	&.active,
	&:focus {
		color: darken($danger, 10%) !important;
	}
}

.btn-lg {
	border-radius: $border-radius;
}