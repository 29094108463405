//input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
//	color: red;
//	opacity: 1; /* Firefox */
//}
//
//input::-webkit-input-placeholder {
//	color: red;
//}
//
//input:placeholder-shown {
//	color: red;
//}
//
//input:-ms-input-placeholder { /* Internet Explorer 10-11 */
//	color: red;
//}
//
//input::-ms-input-placeholder { /* Microsoft Edge */
//	color: red;
//}

fieldset {
	border: 1px solid $border-color;
	padding: 0 $spacer;
	margin-bottom: $spacer;
}
legend {
	display: inline-block;
	width: auto;
	font-size: 1rem;
	color: $text-muted;
}

.form-check-input {
	width: 1px;
	height: 1px;
	position: absolute;
	visibility: hidden;
}

.form-check-input + .form-check-label {
	cursor: pointer;
	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		border: 1px solid $input-border-color;
		background-color: $white;
	}

}

.form-check-input[type="checkbox"] + .form-check-label {
	&:before {
		border-radius: $border-radius-sm;
		width: 18px;
		height: 18px;
	}
}
.form-check-input[type="checkbox"]:checked + .form-check-label {
	&:before {
		background-color: $primary;
		border-color: $primary;
	}
	&:after {
		content: '\f00c';
		border-radius: $border-radius-sm;
		font-family: 'Font Awesome 5 Pro';
		font-weight: bold;
		color: $white;
		display: block;
		width: 18px;
		height: 18px;
		line-height: 20px;
		text-align: center;
		position: absolute;
		left: 0;
		top: 0;
	}
}

.form-check-input[type="radio"] + .form-check-label {
	&:before {
		border-radius: 100%;
		width: 20px;
		height: 20px;
	}
}
.form-check-input[type="radio"]:checked + .form-check-label {
	&:before {
		background-color: $primary;
		border-color: $primary;
	}
	&:after {
		content: '';
		display: block;
		width: 10px;
		height: 10px;
		background-color: $white;
		border-radius: 100%;
		position: absolute;
		left: 5px;
		top: 6px;
	}
}