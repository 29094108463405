@import '../styles/bootstrap';
@import '../styles/mixins';
@import '../styles/variables';

._layout {
	display: grid;
	width: 100vw;
	height: 100vh;
	grid-gap: 10px;
}

.layout {
	composes: _layout;
	grid-template-columns: 380px 1fr;
	grid-template-rows: $navbar-height 1fr;
	grid-template-areas:
		'header header'
		'aside content';
}

.layoutDashboard {
	composes: _layout;
	grid-template-columns: 380px 1fr;
	grid-template-rows: $navbar-height 1fr 71px;
	grid-template-areas:
		'header header'
		'aside content'
		'footer content';
}

.layoutDashboardRerouting {
	composes: _layout;
	grid-template-columns: 380px 350px 1fr;
	grid-template-rows: $navbar-height 1fr 71px;
	grid-template-areas:
		'header header header'
		'aside orders _'
		'footer orders _';
}

.layoutSettings {
	composes: _layout;
	grid-template-columns: 1fr;
	grid-template-rows: #{$navbar-height + $subnavbar-height + 1px} 1fr;
	grid-gap: 0;
	grid-template-areas:
		'header'
		'content'
}

.layoutSettingsWithAside {
	composes: _layout;
	grid-template-columns: 380px 1fr;
	grid-template-rows: #{$navbar-height + $subnavbar-height + 1px} 1fr 71px;
	grid-template-areas:
		'header header'
		'aside content'
		'footer content';
}

.layoutPlanning {
	composes: _layout;
	grid-template-columns: 300px 1fr;
	grid-template-rows: $navbar-height 1fr 60px;
	grid-template-areas:
		'header header'
		'aside content'
		'footer footer';
}

.layoutPlanningWithoutAside {
	composes: _layout;
	grid-template-columns: 1fr;
	grid-template-rows: $navbar-height 1fr;
	grid-template-areas:
		'header'
		'content';
}

.layoutPlanningAssignedOrders {
	composes: _layout;
	grid-template-columns: 260px 350px 1fr;
	grid-template-rows: $navbar-height 1fr 60px;
	grid-template-areas:
		'header header header'
		'aside orders vehicles'
		'footer footer footer';
}

.layoutPlanningRerouteSingle {
	composes: _layout;
	grid-template-columns: 380px 350px 1fr;
	grid-template-rows: $navbar-height 1fr 60px;
	grid-template-areas:
		'header header header'
		'aside orders _'
		'footer footer footer';
}

.layoutRerouting {
	composes: _layout;
	grid-template-rows: $navbar-height 1fr 60px;
	grid-template-areas:
		'header'
		'content'
		'footer';
}

.layoutReroutingSelected {
	position: absolute;
	composes: _layout;
	grid-template-columns: 320px 1fr;
	grid-template-rows: $navbar-height 1fr 60px;
	grid-template-areas:
		'header header'
		'orders content'
		'footer footer';
}

.aside {
	grid-area: aside;
	height: 100%;
	margin-left: $spacer;
	//padding-bottom: $spacer;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	z-index: 2; // because of drag and drop on planning reroute single screen
}

.asideDashboard {
	composes: aside;
	padding-bottom: 0;
}

.asidePlanning {
	composes: aside;
	overflow-y: auto;
}

.content {
	grid-area: content;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
}
.contentSettings {
	grid-area: content;
	display: flex;
	overflow: hidden;
	padding: 1rem 0;
}
.contentPlanning {
}
.contentItem {
	width: 300px;
	flex-shrink: 0;
}

.footer {
	grid-area: footer;
	padding-left: $spacer;
}