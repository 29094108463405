.panel {
	@include BoxShadow;
	background: $gray-100;
	border-radius: $border-radius-lg;
}
.panel--flex {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.panel-header {
	padding: 10px 10px 0;
}
.panel-body {
	overflow-y: auto;
	overflow-x: hidden;
	padding-left: 10px;
	padding-right: 10px;
}