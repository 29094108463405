.table-outline {
	border: 1px solid $table-border-color;
}

.table-grid {
	// For some reason right border causes table to overflow when 100% width is set
	width: calc(100% - 1px);
	tbody tr td:first-child {
		border-left: 1px solid $table-border-color;
	}
	tbody tr td:last-child {
		border-right: 1px solid $table-border-color;
	}
	td {
		vertical-align: middle;
	}
}