@import '../../styles/bootstrap';

.footer {
	grid-area: footer;
	padding-left: $spacer;
	padding-right: $spacer;
	z-index: 1;	// because of map in planning section
}
.footerPanel {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}