@import '../../styles/bootstrap';
@import '../../styles/mixins';

.container {
	border: 1px solid $border-color;
	border-radius: $border-radius;
	background: white;
	padding: .9rem 1rem .8rem;
	margin-bottom: .5rem;
	position: relative;
}

.progress {
	border: 1px solid $border-color;
	height: 29px;
	padding: 2px;
	border-radius: 15px;
	margin-bottom: .5rem;
}
.progressBar {
	min-width: 23px;
	max-width: 100%;
	height: 23px;
	line-height: 23px;
	color: white;
	padding-right: 3px;
	text-align: right;
	border-radius: 12px;
	font-size: 12px;
	background-image: linear-gradient(to right, $delivery 0%, $pickup 100%);
}
.progressBarStart {
	composes: progressBar;

	background-image: none;
	background-color: $gray-500;
}
.progressBarEnd {
	composes: progressBar;

	background-image: none;
	background-color: $success;
}
.progressBarCancelled {
	composes: progressBar;

	background-image: none;
	background-color: $danger;
}
.progressBarLoader {
	composes: progressBar;

	background-image: none;
	background-color: $gray-100;

	@include PulseLoader;
}

.moreInformation {
	top: 0;
	right: -.5rem;
}
