@import 'styles/bootstrap';

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($secondary, .8);
	overflow: auto;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 3rem 1rem;
	opacity: 0;
	transition: $transition-fade;
	z-index: $zindex-modal-backdrop;
}

.overlayAfterOpen {
	opacity: 1;
}

.overlayBeforeClose {
	opacity: 0;
}

.modal {
	outline: none;
	position: relative;
	max-width: 520px;
	width: 100%;
	padding: 1rem;
	transition: $transition-base;
	transform: translateY(-20px);
}

.modalAfterOpen {
	transform: translateY(0);
}

.modalBeforeClose {
	transform: translateY(-20px);
}

.close {
	position: absolute;
	top: .4rem;
	right: .5rem;
}