@import '../../styles/bootstrap';

// When changing, OrderTypeBadgeEnum must be also changed
$badges: (
	0: $body-color,
	1: $delivery,
	2: $pickup,
	3: $requisition,
	4: $unassigned,
	5: $completed,
	6: $cancelled,
);

.badge {
	font-weight: $font-weight-semibold;
	font-size: .92rem;
	color: white;
	padding: 4px .8rem 4px 4px;
	background-color: $body-color;
	border-radius: 1.2rem;
	display: inline-block;
	margin-right: .5rem;
	margin-bottom: .5rem;
	text-transform: uppercase;
	height: 25px;
	appearance: none;
	border: none;
}

.unselected {
	opacity: .5;
}

@each $type, $color in $badges {
	.badge--#{$type} {
		composes: badge;
		background-color: $color;
		.count {
			color: $color;
		}
	}
}

.count {
	background: white;
	border-radius: 1rem;
	padding: 0 .5rem;
	height: 17px;
	display: inline-block;
	margin-right: .5rem;
}