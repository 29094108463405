@import '../../../styles/bootstrap';

.orders {
	grid-area: orders;
	overflow: hidden;
	display: flex;
	align-items: flex-start;
	z-index: 2;
}
.vehicles {
	grid-area: vehicles;
	overflow: auto;
}
.vehicle {
	width: 350px;
	display: flex;
	flex-direction: column;
	max-height: 100%;
}
.noOrders {
	border: 1px dashed $border-color;
	height: 98px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $text-muted;
	border-radius: $border-radius-sm;
	margin-bottom: .5rem;
}