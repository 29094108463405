@import '../../styles/bootstrap';

.icon {
	position: absolute;
	left: .8rem;
	color: $secondary;
	font-size: 1.2rem;
	top: 0.85rem;
}

.input {
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

.clear {
	position: absolute;
	right: .2rem;
	color: $secondary;
	font-size: 1.2rem;
	top: 0;
}